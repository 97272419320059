import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    guard: true,
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ProfileDetails/Profile")),
  },

  {
    guard: true,
    exact: true,
    path: "/coin-stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Coin/Coinstake")),
  },
  {
    exact: true,
    path: "/buy-coin",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Coin/Buycoin")),
  },
  {
    guard: true,
    exact: true,
    path: "/plot",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Plots/Index")),
  },
  {
    guard: true,
    exact: true,
    path: "/upload-profile",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/ProfileDetails/UploadProfile")
    ),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/ContactUs")),
  },

  {
    exact: true,
    path: "/term",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/TermCondition")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/AboutUs")),
  },
  {
    exact: true,
    path: "/live-class",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LiveClass/ClassRoom")),
  },
  {
    exact: true,
    path: "/classroom-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/LiveClass/ClassRoomDetails")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPage/PrivacyPolicy")),
  },
  {
    guard: true,
    exact: true,
    path: "/plot-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/PlotDeatils")),
  },
  {
    exact: true,
    path: "/detail-page",
    layout: HomeLayout,
    component: lazy(() => import("src/component/DetailPage")),
  },
  {
    exact: true,
    path: "/institute",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Institutes/Institute")),
  },
  // {
  //   exact: true,
  //   path: "/institute",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/SetupInstitute/Institute")),
  // },
  {
    exact: true,
    path: "/blog-list",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Blog")),
  },
  {
    exact: true,
    path: "/institute-details",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/SetupInstitute/InstituteDetails")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/infrastructure",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Infrastructure/Infrastructure")
    ),
  },
  {
    exact: true,
    path: "/institute-details-courses",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Institutes/InstitutesDetail")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Marketplace")),
  },

  {
    guard: true,
    exact: true,
    path: "/marketplace-Detail",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/MarketplaceDetail/MarketplaceDetail")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/marketplace-detail-plots",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/PlotMarketplaceDetail/MarketplaceDetail")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  // {
  //   // guard: true,
  //   exact: true,
  //   path: "/analitics",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Dasboard/Index")),
  // },
  {
    component: () => <Redirect to="/404" />,
  },
];
