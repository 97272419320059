import React from "react";

const Logo = (props) => {
  return (
    <>
      <img
        src="/images/logo.png"
        alt="Logo"
        {...props}
       
      />
    </>
  );
};

export default Logo;
