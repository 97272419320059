export const baseurl = "https://node.hexaverses.com/"; //client production server
// export const baseurl = "https://node-eduverse.mobiloitte.org/"; //staging

let base = `${baseurl}api/v1`;
let nft = `${base}/nft`;
let user = `${base}/user`;
let admin = `${base}/admin`;
let contactUs = `${base}/contactUs`;
let collection = `${base}/collection`;
let category = `${base}/category`;
let building = `${base}/building`;
let course = `${base}/course`;
let order = `${base}/order`;
let stack = `${base}/stack`;
let kyc = `${base}/kyc`;
let enroll = `${base}/enroll`;
let bid = `${base}/bid`;

const Apiconfigs = {
  //API endpoints for user's://
  login: `${user}/login`,
  uploadMultipleImage: `${user}/uploadMultipleImage`,
  profile: `${user}/profile`,
  register: `${user}/register`,
  resendOTP: `${user}/resendOTP`,
  verifyOTP: `${user}/verifyOTP`,
  editProfile: `${user}/editProfile`,
  uploadImage: `${user}/uploadImage`,
  connectWallet: `${user}/connectWallet`,
  resetPassword: `${user}/resetPassword`,
  forgotPassword: `${user}/forgotPassword`,
  userSubscribe: `${user}/userSubscribe`,
  listNftHistoryForUser: `${user}/listNftHistoryForUser`,
  allPlotsAndBuildings: `${user}/allPlotsAndBuildings`,
  redeemRequest: `${user}/redeemRequest`,
  myRedeemRequest: `${user}/myRedeemRequest`,
  getUsdtPrice: `${user}/getUsdtPrice`,
  addWhiteListUser: `${user}/addWhiteListUser`,
  whitelistUsers: `${user}/whitelistUsers`,

  //API endpoints for collection://
  viewPlot: `${collection}/viewPlot`,
  //API endpoints for collection://
  listCategory: `${category}/listCategory`,

  //API endpoints for stack://
  stacking: `${stack}/stacking`,
  stackingList: `${stack}/stackingList`,
  pairStackingAmountAndCourses: `${stack}/pairStackingAmountAndCourses`,

  //API endpoints for nft://
  addNft: `${nft}/addNft`,
  listNft: `${nft}/listNft`,
  viewNft: `${nft}/viewNft`,

  //blogs//
  viewBlogs: `${admin}/viewBlogs`,
  listBlogs: `${admin}/listBlogs`,
  packageList: `${admin}/packageList`,

  //building
  listBuilding: `${building}/building`,
  buildingDetails: `${building}/building`,
  buildingBuy: `${building}/building`,
  listInstituteParticular: `${building}/listInstituteParticular`,
  viewInfra: `${building}/viewInfra`,
  listUserBuilding: `${building}/listUserBuilding`,
  listInstitute: `${building}/listInstitute`,
  viewInstitute: `${building}/viewInstitute`,
  createInstitute: `${building}/createInstitute`,
  editInstitute: `${building}/editInstitute`,

  //course
  course: `${course}/course`,
  listMyCourse: `${course}/listMyCourse`,
  courseCategoryList: `${course}/courseCategoryList`,

  //order
  placeOrderList: `${order}/placeOrderList`,
  createOrders: `${order}/createOrders`,
  viewPlaceOrder: `${order}/viewPlaceOrder`,
  updateOrder: `${order}/updateOrder`,
  cancelOrder: `${order}/cancelOrder`,
  buyOrder: `${order}/buyOrder`,

  //BID
  createBid: `${bid}/createBid`,
  acceptBid: `${bid}/acceptBid`,
  cancelBid: `${bid}/cancelBid`,
  rejectBid: `${bid}/rejectBid`,
  listBid: `${bid}/listBid`,
  bidListByOrder: `${bid}/bidListByOrder`,
  bidList: `${bid}/bidList`,
  myBidList: `${bid}/myBidList`,

  //KYC
  addKyc: `${kyc}/addKyc`,

  //enroll
  enroll: `${enroll}/enroll`,
  listEnrolldUser: `${enroll}/listEnrolldUser`,

  //contactUs
  contactUs: `${contactUs}/contactUs`,
};

export default Apiconfigs;
