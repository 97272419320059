import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";
import { softShadows, strongShadows } from "./shadows";

const baseOptions = {
  typography,
  overrides: {
    MuiMenuItem:{
      root:{
        width: "auto", 
        overflow: "hidden", 
        fontSize: "12px", 
        boxSizing: "border-box", 
        minHeight: "48px", 
        fontFamily: "'Roboto', sans-serif", 
        fontWeight: "300", 
        lineHeight: "23px", 
        whiteSpace: "nowrap", 
        padding: "10px !important",
      },
  
    },
    MuiDropzonePreviewList:{
      image:{
        height:"30px",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: "calc(100% - 64px)",
      },
      paperFullWidth: {
        "@media(max-width:767px)": {
          width: "90% !important",
          margin: "0",
        },
      },
    },
    
    MuiList: {
      root: {
        margin: "0",
        padding: "0",
        position: "relative",
        listStyle: "none",
        zIndex: "999",
      },
    },
    MuiDialogContent: {
      root: {
        "@media(max-width:767px)": {
          padding: "8px",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "12px 40px",
        "@media(max-width:767px)": {
          padding: "16px 20px",
        },
      },
    },
    MuiTimelineConnector: {
      root: {
        width: "2px",
        flexGrow: "1",
        backgroundColor: "#bdbdbd",
        height: "65px",
        zIndex: "1",
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      button: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiDropzoneArea: {
   
      root: {
        backgroundColor: "transparent",
        minHeight: "104px",
      },
      icon: {
        color: "#fff",
        width: "27px",
        height: "40px",
      },
      text: {
        marginTop: "7px",
        marginBottom: "3px",
        fontSize: "16px",
        fontWeight: "300",
        color: "rgba(255, 255, 255, 0.25)",
      },
    },
    MuiTable: {
      root: {
        width: "100%",
        display: "table",
        borderCollapse: "separate",
        borderSpacing: "0 3px",
      },
    },
    MuiTableCell: {
      root: {
        border: "none !important",
        padding: "14px 20px !important ",
        color: "#fff",
        fontWeight: "300",
        fontSize: "15px !important",
        textAlign: "left",
      },
      body: {
        padding: "14px 20px !important ",
      },
      head: {
        color: "#fff",
        fontSize: "16px !important",
        fontWeight: "300",
      },
    },
    MuiTimeline: {
      root: {
        padding: "0px 0px",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiAvatar: {
      img: {
        minHeight: "200px",
      },
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },

      containedSizeLarge: {
        padding: "13px 42px",
        fontSize: "16px",
        fontWeight: "500",
        "@media(max-width:767px)": {
          fontSize: "12px",
        },
      },
      outlinedSizeLarge: {
        padding: "13px 42px",
        fontSize: "16px",
        fontWeight: "300",
      },
      containedPrimary: {
        color: "#fff",
        backgroundColor: "transparent",
        "&:hover": {
          background:
            "linear-gradient(260.62deg, #7C162E -41.31%, #9C162E 107.21%)",
        },
      },

      outlined: {
        borderRadius: "50px",
        fontSize: "16px",
        fontWeight: "300",
        border: "1px solid rgb(153 143 143 / 50%)",
        "&:hover": {
          border: "1px solid rgb(78 17 31) !important",
        },
      },
      containedSizeSmall: {
        fontSize: "11px",
      },
      // outlinedPrimary: {
      //   color: "#000",
      //   border: "1px solid #9C162E",
      //   filter: "drop-shadow(0px 13px 27px rgba(0, 0, 0, 0.25))",
      //   padding: "11px 36px !important",
      //   fontSize: "16px",
      //   fontWeight: "400",
      //   borderRadius: "30px",
      //   "&:hover": {
      //     background:
      //       "linear-gradient(260.62deg, #7C162E -41.31%, #9C162E 107.21%)",
      //     color: "#fff",
      //     border: "none",
      //   },
      //   "@media (max-width: 600px)": {
      //     padding: "7px 15px !important",
      //   },
      // },
    },

    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.45)" },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiPickersModal:{
        dialogRoot:{
        boxShadow: "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff, 1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47), -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
      },},
      MuiFilledInput: {
        root: { backgroundColor: " #f8f7f7", color: "#000" },
      },
      MuiOutlinedInput: {
        input: {
          borderRadius: "10px",
          padding: "13.5px 12px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#000",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
      MuiButton: {
        contained: {
          background: "rgba(204, 35, 75, 0.02)",
          boxShadow:
            "0px 4px 20px rgba(254, 116, 182, 0.7), inset 0px 0px 5px #FF4264, inset 0px 0px 60px #FF002E",
          borderRadius: "50px",
          fontSize: "16px",
          fontWeight: "300",
          "@media(max-width:767px)": {
            fontSize: "12px",
          },
        },
        outlinedPrimary: {
          color: "#000",
          "&:hover": {
            border: "1px solid rgb(69 16 30)",
            background: "rgba(204, 35, 75, 0.02)",
            boxShadow:
              "0px 4px 20px rgba(254, 116, 182, 0.7), inset 0px 0px 5px #FF4264, inset 0px 0px 60px #FF002E",
            borderRadius: "50px",
            fontSize: "16px",
            fontWeight: "300",
            border: "none",
            color: "#fff",
          },
        },
      },
      MuiInputBase: {
        input: {
          fontSize: 14,
          color: "#000",
          // height: "0.1876em",
          // padding: "6px 16px 7px",
          "&:-webkit-autofill": {
            WebkitBackgroundClip: "text !important",
            WebkitTextFillColor: "#000 !important",
          },
        },
        root: {
          fontSize: "14px",
        },
      },
      MuiPaper: {
        elevation1: {
          padding: "10px",
          background: "#FFFFFF",
          boxShadow:
            "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff,1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47),  -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
          borderRadius: "10.0968px",
        },
        elevation3: {
          background: "#fff",
          borderRadius: "8px",
          border: "1.5px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0px 7px 12px rgba(0, 0, 0, 0.15)",
        },
        elevation4: {
          background: "#fff",
          border: "1.5px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0px 7px 12px rgba(0, 0, 0, 0.15)",
          borderRadius: "10.0968px",
        },
      },

      MuiDialog: {
       
        paper: {
          // boxShadow:
          //   "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff,1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47),  -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
          // backdropFilter: "blur(30px)",
          borderRadius: "15px",
          overflow: "hidden",
          overflowY: "none",
          background:
            "linear-gradient(90deg, rgba(222,221,221,1) 0%, rgba(145,145,163,1) 56%, rgba(204,201,201,1) 100%)",
          "@media(max-width:768px)": {
            backdropFilter: "none !important",
          },
          "&::before": {},
        },
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#fff",
        connectWallet: "#FFFFFF",
        contactUsrightBackgroun: "#FFFFFF",
        footerbg:
          "linear-gradient(90.51deg, rgba(254, 116, 182, 0.08) 0.58%, rgba(213, 157, 254, 0.08) 99.24%)",
        blogbg: "rgba(247, 247, 247, 0.5)",
        faqbg: "rgba(247, 247, 247, 0.5)",
        accDetail: "rgba(0, 0, 0, 0.04)",
        listDot: "#7F43CE",
        header:
          "linear-gradient(90.51deg, #3B1948 0.58%, rgba(213, 157, 254, 0.2) 99.24%)",
          headerBox:"rgb(61 28 74)",
        headerfilter: "blur(50px)",
        contactbg: "#fff",
        tablebackground: "rgb(7 6 6 / 15%)",
        bright: "rgba(0, 0, 0, 0.08)",
        tabbg: "rgba(204, 35, 75, 0.02)",
        dark: "rgba(0, 0, 0, 0.10)",
        tabshadow:
          "0px 4px 20px rgb(254 116 182 / 70%), inset 0px 0px 5px #ff4264, inset 0px 0px 60px #ff002e",
      },
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "rgba(0, 0, 0, 0.87)",
      },
      text: {
        primary: "#000",
        dull: "rgba(0, 0, 0, 0.6)",
        borderColor: "rgba(0, 0, 0, 0.42)",
        graypink: "#7F43CE",
        greyBlack: "#000",
        level: "#fff",
        dashedborder: "0.5px dashed rgba(0, 0, 0, 0.1)",
      },
      economy: {
        bgeconomy1:
          "linear-gradient(105.56deg, #F95A64 -0.01%, #FFB8BB 115.01%)",
        bgeconomy2:
          "linear-gradient(106.2deg, #FFB85B -1.34%, #EB8F16 -1.33%, #FFBE67 92.91%)",
        bgeconomy: "linear-gradient(108.81deg, #1BC0E3 -4.09%, #89E6F8 98.12%)",
        bgeconomy3:
          "linear-gradient(101.09deg, #084FC8 -0.98%, #0C67FF -0.97%, #5492FF 96.69%)",
        bordereconomy: "1.5px solid rgba(255, 255, 255, 0.25)",
        boxShadoweconmy: "0px 5px 12px rgba(0, 0, 0, 0.08)",
        filtereconomy: "blur(31.3725px)",
        bgmain: "#fff",
        bgsub: "rgba(255, 255, 255, 0.8)",
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    overrides: {
      MuiPickersToolbar:{
        toolbar:{
          backgroundColor:"rgb(35 12 12)",
        },
      },
      MuiPickersModal:{
        dialogRoot:{
        boxShadow: "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff, 1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47), -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
      },},
      MuiOutlinedInput: {
        input: {
          borderRadius: "10px",
          padding: "13.5px 12px",
          "&:-webkit-autofill": {
            "-webkit-background-clip": "text !important",
            // transitionDelay: "9999s",
            "caret-color": "transparent",
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "#fff",
          },
          "&:-internal-autofill-selected": {
            color: "#fff",
          },
        },
      },
      MuiButton: {
        contained: {
          background: "rgba(204, 35, 75, 0.1)",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px #B61733, inset 0px 0px 35px #B51632",
          borderRadius: "50px",
          fontSize: "16px",
          fontWeight: "300",
          "@media(max-width:767px)": {
            fontSize: "12px",
          },
        },
        outlinedPrimary: {
          color: "#fff",
          "&:hover": {
            border: "1px solid rgb(69 16 30)",
            background: "rgba(204, 35, 75, 0.1)",
            boxShadow:
              "0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632",
            border: "none",
          },
        },
      },
      MuiInputBase: {
        input: {
          fontSize: 14,
          color: "#FFFFFF",
          // height: "0.1876em",
          // padding: "6px 16px 7px",
          "&:-webkit-autofill": {
            WebkitBackgroundClip: "text !important",
            WebkitTextFillColor: "#fff!important",
          },
        },
        root: {
          fontSize: "14px",
        },
      },
      MuiPaper: {
        elevation1: {
          padding: "10px",
          background: "rgba(0, 0, 0, 0.1)",
          // border: "1.5px solid #171515",
          boxShadow:
            "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff,1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47),  -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
          borderRadius: "10.0968px",
        },
        elevation3: {
          background:
            "linear-gradient(90deg, rgba(81, 40, 135, 0.7) 0%, rgba(81, 40, 135, 0.2) 100%);",
          borderRadius: "8px",
          border: "none",
          boxShadow: "none",
        },
        elevation4: {
          background: "rgba(0, 0, 0, 0.1)",
          border: "1.5px solid #171515",
          boxShadow: "0px 4.03873px 20.1936px #14101B",
          borderRadius: "10.0968px",
        },
      },
      MuiDialog: {
        paper: {
          // boxShadow:
          //   "2px 2px 5px 0px #6e00ff, 1px 0px 0 2px #ff2c52, 1px 0 0 2px #6e00ff,1px -1px 0 2px #97001b, -1px 0 0 2px rgb(209 47 47),  -1px 1px 0 2px rgb(189 13 13), 1px 1px 0 2px rgb(254 90 90), -1px -1px 0 2px rgb(244 26 26)",
          // backdropFilter: "blur(30px)",
          borderRadius: "20px",
          // borderRadius: "10px",
          background: "#06010D",
          overflow: "hidden",
          overflowY: "none",
          "@media(max-width:768px)": {
            backdropFilter: "none !important",
          },
        },
      },
    },
    typography: {
      fontFamily: "'Roboto', sans-serif",
    },
    palette: {
      type: "dark",
      background: {
        connectWallet: "linear-gradient(90deg, rgba(81, 40, 135, 0.7) 0%, rgba(81, 40, 135, 0.2) 100%)",
        contactUsrightBackgroun: "linear-gradient(146.96deg, rgba(156, 22, 46, 0.25) 2.16%, rgba(103, 52, 170, 0.25) 97.89%)",
        default: "#12101A",
        blogbg: "rgba(66, 66, 66, 0.2)",
        faqbg: "rgba(66, 66, 66, 0.2)",
        accDetail: "rgba(66, 66, 66, 0.2)",
        listDot: "rgba(217, 217, 217, 0.87)",
        header: "rgba(255, 255, 255, 0.05)",
        headerBox:"rgba(255, 255, 255, 0.05)",
        headerfilter: "blur(25px)",
        contactbg: "rgba(35, 31, 44, 0.25)",
        tablebackground: "rgba(255, 255, 255, 0.05)",
        bright: "rgba(250, 252, 255, 0.2)",
        footerbg: "#12101A",
        tabbg: "rgba(204, 35, 75, 0.1)",
        dark: "rgb(133 9 9 / 87%)",
        tabshadow:
          "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px #B61733, inset 0px 0px 35px #B51632",
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "rgba(255, 255, 255, 0.87)",
      },

      white: {
        main: "#000",
        dullWhite: "#383636",
      },
      text: {
        primary: "#fff",
        dull: "rgba(255, 255, 255, 0.6)",
        graypink: "rgba(255, 255, 255, 0.6)",
        level: "#000",
        borderColor: "rgba(0, 0, 0, 0.42)",
        greyBlack: "rgba(255, 255, 255, 0.6) ",
        dashedborder: " 1px dashed rgba(255, 255, 255, 0.25)",
      },
      economy: {
        bgeconomy1: "rgba(217, 217, 217, 0.07)",
        bgeconomy2: "rgba(217, 217, 217, 0.07)",
        bgeconomy: "rgba(56, 3, 39, 0.1)",
        bordereconomy: "1px solid #A92A48",
        boxShadoweconmy:
          "inset -10px -10px 12px rgba(169, 42, 72, 0.2), inset 10px 10px 12px rgba(156, 22, 46, 0.25)",
        filtereconomy: "blur(5px)",
        bgmain: "rgba(255, 255, 255, 0.87)",
        bgsub: "rgba(255, 255, 255, 0.6)",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
