import axios from "axios";
import Apiconfigs from "../config";
import { toast } from "react-toastify";

export const uploadMultipleFile = async (endPoint, dataToSend) => {
  let res, formdata;
  formdata = new FormData();
  Object.values(dataToSend).forEach((res) => {
    formdata.append("uploaded_file", res);
  });
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      data: formdata,
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    // return error?.response?.data?.responseMessage;
  }
};
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.data?.responseMessage === "jwt expired") {
      window.localStorage.removeItem("email");
      window.localStorage.removeItem("password");
      window.localStorage.removeItem("userType");
      window.localStorage.removeItem("token");
      window.location = "/";
    }

    // toast.error(error?.response?.data?.message);
    return error?.response?.data?.responseMessage;
  }
};

export const getDataHandlerAPI = async (endPoint, token, id) => {
  try {
    const res = await axios({
      method: "GET",
      url: Apiconfigs[endPoint],
      headers: {
        token: token,
      },

      params: {
        orderId: id ? id : undefined,
      },
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    // toast.error(error?.response?.data?.responseMessage);
    return error?.response?.data?.responseMessage;
  }
};

export const putAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PUT",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getParticularObjectInParamsDataHanndler = async (
  endPoint,
  dataToSend
) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${Apiconfigs[endPoint]}`,
      headers: {
        token: window.localStorage.getItem("token"),
      },
      params: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: Apiconfigs[endPoint],
      headers: {
        token: window.localStorage.getItem("token"),
      },
      params: {
        _id: _id,
      },
    });
    if (res.data.code === 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${Apiconfigs[endPoint]}/${_id}`,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const addImageHandler = (img) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", img);
    axios({
      method: "POST",
      url: Apiconfigs.ipfsUpload,
      data: formData,
      headers: {
        token,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log("res-----", res);
        if (res.data.statusCode === 200) {
          resolve(res.data.result.imageUrl);
        } else {
          reject(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        reject(false);
      });
  });
};

export const dataPostHandler1 = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: Apiconfigs[endPoint],
      // headers: {
      //   token: window.localStorage.getItem("token"),
      // },
      data: dataToSend,
    });
    if (res.data.statusCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);

    // toast.error(error?.response?.data?.message);
    return error?.response?.data?.responseMessage;
  }
};
