import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Container,
  Button,
  Typography,
  makeStyles,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import * as yep from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import { dataPostHandler } from "src/apiConfig/service";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "30px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    "& h4": {
      fontSize: "30px",
      marginBottom: "24px",
      color:"#fff",
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
      background: "none !important",
    },

    "& svg": {
      color: "#693993",
      cursor: "pointer",
    },
  },
}));

const Signup = ({ setOpenLogin, setOpenReset }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const [errmsg, setErrMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const formInitialSchema = {
    password: "",
    confirmPassword: "",
  };
  const formValidationSchema = yep.object().shape({
    password: yep
      .string()
      .required("Password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Please enter a strong password"
      )
      .min(6, "Please enter atleast 6 characters.")
      .max(16, "You can enter only 16 characters."),
    confirmPassword: yep
      .string()
      .required("Confirm password is required.")
      .oneOf(
        [yep.ref("password"), null],
        "Confirm password or password did not match."
      ),
  });
  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await dataPostHandler("resetPassword", {
        email: window.localStorage.getItem("email"),
        password: values.password,
        confirmPassword: values.confirmPassword,
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        setIsSubmitting(false);
        toast.success("Your password has been changed successfully.");
        setOpenReset(false);
        setOpenLogin(true);
      } else {
        setErrMsg(response);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
    >
      <Box className={classes.mainSignupFrom}>
        <div className="shade13"></div>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton className="closeIcon" onClick={() => setOpenReset(false)}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
        <Container maxWidth="lg">
          {/* <Box className="logoSection" onClick={() => history.push("/")}>
            <Logo />
          </Box> */}
          <Box className={classes.Register}>
            <Typography variant="h4" color="primary">
              Create your new password.
            </Typography>
          </Box>

          <Box className={classes.HeaddingBox}></Box>
          <Box className={classes.formikBox}>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        variant="standard"
                        fullWidth
                        name="password"
                        id="password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword ? (
                                    <VisibilityIcon
                                      className={classes.iconsclass1}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      className={classes.iconsclass1}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        type={showPassword1 ? "text" : "password"}
                        placeholder="Confirm Password"
                        variant="standard"
                        fullWidth
                        name="confirmPassword"
                        id="confirmPassword"
                        value={values.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword1(!showPassword1)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword1 ? (
                                    <VisibilityIcon
                                      className={classes.iconsclass1}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      className={classes.iconsclass1}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText error className={classes.helperText}>
                        {touched.confirmPassword && errors.confirmPassword}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box mb={2} mt={2}>
                        {errmsg !== "" && (
                          <FormHelperText error>{errmsg}</FormHelperText>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: "2px" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              Please wait... <ButtonCircularProgress />
                            </>
                          ) : (
                            "Register"
                          )}
                        </Button>
                      </Box>
                      <Box>
                        <Typography
                          variant="h4"
                          style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenReset(false);
                            setOpenLogin(true);
                          }}
                        >
                          Login
                        </Typography>
                      </Box>

                      <div className="shade14"></div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Signup;
