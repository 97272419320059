import React, { useContext } from 'react';
import { makeStyles, Box, } from '@material-ui/core';
import SettingsContext from "src/context/SettingsContext";



const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  animateBox: {
    height: "300px",
    width: "300px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "& img": {
      position: "absolute",
      left: "0px",
      top: "0px",
      maxWidth: "100%",
    },
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);

  return (
    <div className={classes.root}>
      <div className="footerleftDark"></div>
      <div className="footerrightDark"></div>
      <Box className={classes.animateBox}>
        {/* <LinearProgress height={10} /> */}
        <img className={classes.loader} src="/images/loader/logo.png" alt="loader" />
        <img className={`${classes.loader} animateLogo`} src="/images/loader/1st.png" alt="loader" />
        <img className={`${classes.loader} animateLogo2`} src="/images/loader/2nd.png" alt="loader" />
        <img className={`${classes.loader} animateLogo3`} src="/images/loader/3rd.png" alt="loader" />
        <img className={`${classes.loader} animateLogo4`} src="/images/loader/4th.png" alt="loader" />
        <img className={`${classes.loader} animateLogo5`} src="/images/loader/5th.png" alt="loader" />
        <img className={`${classes.loader} animateLogo6`} src="/images/loader/6th.png" alt="loader" />

      </Box>
    </div>
  );
}
