import React, { useState, useContext } from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import Logo from "./Logo";
import Signup from "./Signup";
import PageLoading from "./PageLoading";
import AnimateLogo from "./AnimateLogo";
import Login from "src/views/pages/Auth/Login";
import CloseIcon from "@material-ui/icons/Close";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    background: "#1B0F20",
  },

  earth: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    "@media(max-width:960px)": {
      display: "none",
    },
  },
  container: {
    position: "relative",
    overflow: "hidden",
    width: "678px",
    maxWidth: "100%",
    minHeight: "400px",
  },
  leftSideHeight: {
    minHeight: "550px",
    position: "relative",
    "@media(max-width:768px)": {
      minHeight: "316px",
    },
  },
  rightSideHeight: {
    minHeight: "550px",
    "@media(max-width:768px)": {
      // minHeight: "700px",
    },
  },
  desktopView: {
    "@media(min-width:768px)": {
      width: "813px",
      maxWidth: "100%",
    },
  },
}));
export default function Register({ setOpenSignup, setOpenForgot, user }) {
  const classes = useStyles();
  const [swapPanel, setSwapPanel] = useState(false);
  const themeSeeting = useContext(SettingsContext);
  return (
    <Box className={[classes.desktopView, "newModalBorder"]}>
      <Box
        className={
          themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
        }
      >
        {/* <Container> */}
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          // style={{ marginBottom: "20px" }}
          // style={{ marginBottom: "20px" }}
        >
          <IconButton
            className="closeIcon"
            onClick={() => {
              setOpenSignup(false);
              user.setOpenRegister(false);
            }}
            style={{ position: "absolute", right: "0", zIndex: "999" }}
          >
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
        <Box
          className={[
            "container",
            swapPanel ? "right-panel-active" : null,
            !swapPanel ? classes.leftSideHeight : classes.rightSideHeight,
          ]
            .filter(Boolean)
            .join(" ")}
          id="container"
          // style={swapPanel ? {classes.leftSideHeight} : {}}
        >
          <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "logindarkleftModal"
                : "loginlightModal"
            }
          ></div>
          <div
            className={
              themeSeeting?.settings?.theme === "DARK"
                ? "loginDarktModal"
                : "loginlightrightModal"
            }
          ></div>

          <Box className="form-container sign-up-container noscroll" id="left">
            <Box
              className={`${classes.Rightpart} noscroll`}
              style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}
            >
              <Signup setSwapPanel={(data) => setSwapPanel(data)} />
            </Box>
          </Box>
          <Box className="form-container sign-in-container">
            <Login
              setSwapPanel={(data) => setSwapPanel(data)}
              setOpenSignup={(data) => setOpenSignup(data)}
              setOpenForgot={(data) => setOpenForgot(data)}
            />
          </Box>
          <Box className="overlay-container">
            <Box className="overlay">
              <Box className="overlay-panel overlay-left">
                <Box className="leftPanelAuth">
                  <img
                    src="images/auth/leftAvatarNew.png"
                    alt="" className="avatar"
                  />
                  <Box className="pulseLogo">
                    <AnimateLogo />
                  </Box>
                </Box>
              </Box>

              <Box className="overlay-panel overlay-right">
                <Box className="rightPanelAuth">
                  <img
                    src="images/auth/rightAvatarNew.png"
                    alt="" className="avatar"
                  />
                  <Box className="pulseLogo">
                    <AnimateLogo />
                  </Box>
                </Box>
                
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </Container> */}
      </Box>
    </Box>
  );
}
