import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import { ethers } from "ethers";
import { toast } from "react-toastify";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 4)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}

export function copyTextByValue(value) {
  // var copyText = document.getElementById(id);
  // copyText.select();
  // copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(value);
  toast.info(`Copied   ${sortAddress(value)}`);
}
export function copyTextById(id) {
  var copyText = document.getElementById(id);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator.clipboard.writeText(copyText.value);
  toast.info(`Copied ${copyText.value}`);
}
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export async function handleUpload(files, e) {
  e.preventDefault();
  const reader = new FileReader();
  reader.onload = async (e) => {
    const text = e.target.result;
    // console.log(typeof text);
    // console.log(text);
  };
  reader.readAsText(e.target.files[0]);
  return e.target.files[0];
}
export const getWeb3Obj = () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export function fromWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei =
      Number(balanceOfWei.toString()) / 10 ** Number(decimals);
    return balanceOfFromWei;
  } else {
    return balanceOfWei;
  }
}
export function toWeiDecimals(balanceOfWei, decimals) {
  if (balanceOfWei) {
    const balanceOfFromWei = ethers.utils.parseUnits(
      balanceOfWei.toString(),
      decimals
    );
    return balanceOfFromWei.toString();
  } else {
    return balanceOfWei;
  }
}
export function isValidname(value) {
  var nameRegx = /^[A-Za-z]+$/;
  const isValidName = nameRegx.test(value);
  return isValidName;
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
